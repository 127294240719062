<template>

	<div class="panLback"></div>

	<div class="panmap">
		
		<div class="nav">
			<ul>
				<li><p index=0 class="currentqwys">全部</p></li>
				<li><p index=1><span class="color_1">● </span>企业</p></li>
				<li><p index=2><span class="color_1">● </span>教育</p></li>
				<li><p index=3><span class="color_1">● </span>医疗卫生</p></li>
				<li><p index=4><span class="color_1">● </span>交通枢纽</p></li>
				<li><p index=5><span class="color_1">● </span>美食</p></li>
				<li><p index=6><span class="color_1">● </span>公园</p></li>
				<li><p index=7><span class="color_1">● </span>旅游景点</p></li>
				<li><p index=8><span class="color_1">● </span>配套建设</p></li>
			</ul>
		</div>

		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapZTDT />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div>		
    	<DIALOG_VRImg ref="child_dialog_vrimg" />
	</div>

  
	<div class="panRback"></div>


</template>

<script>
import MapZTDT from '@/components/map/MapZtdt.vue'

import DIALOG_VRImg from '@/components/dialog/dialog_vrimg.vue'


export default {

	components: {
		MapZTDT,
	
		DIALOG_VRImg,
	},
 

	methods: {
		
		openVRImg(ptid, vrURL) {
			console.log(vrURL);
			this.$refs.child_dialog_vrimg.open(vrURL);
		},


	},

}
</script>

<style scoped>

.panmap {		
	top: 0px;
	left: 190px;
	width: 2250px;
	height: 945px;
	text-align: center;
  	position:relative;
  	overflow: hidden;
	
}


.panLback {	
	top: 0px;
	left: -300px;
	width: 500px;
	height: 945px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/kjcx/lback.png") no-repeat; 
}

.panRback {	
	top: 0px;
	right: 0px;
	width: 200px;
	height: 945px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/kjcx/rback.png") no-repeat; 
}



.nav {
    
    z-index: 100;
    
    top: 100px;
    left: 665px;
    width: 923px;
    height: 76px;

    position:absolute;
	background: url("~@/assets/images/kjcx/kjcxmapnav.png") no-repeat; 	

}

.nav ul {   
    top: 20px;
    left: 15px; 
    position:absolute;
}

.nav li {    
	float: left;
    width: 99px;
	cursor: pointer;

    text-align: center;
}

.nav li p{    
    margin: 0 0px 0 0px;
    top: 10px;
	font-size: 16px;
	line-height: 30px;	
	text-align: center;

    
    border-radius: 15px;
    background-color: rgb(255, 255, 255, 0.02);	

	color: rgb(255, 255, 255, 0.6);	
}

.nav li .currentqwys{    
    background-color: rgb(255, 255, 255, 0.2);	
	color: rgb(255, 255, 255, 0.8);	
}


</style>