<template>
		
    <div id="cesiumContainer">
        <!-- <div id="slider"></div> -->
    </div>

</template>


<script>
import { MapInit, MapAddDom_Amap, MapViewInit, MapAddDom_Tianditu, MapViewerRemoveAll, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';
import { getZtdt } from '../../network/kjcx';
import { getMedia, getCode } from '../../network/media';
import { get3dTiles, getTiltphoto } from '../../network/gis';

export default {
    data () {
        return {
            viewer :'',
            tileset: '',
            
			mediaSetting: {},
			gisDemSetting: {},
            ztData: [],
            currentid: 0,  // 当前id（0=未选中）
            
            dom_tianditu: {},
            dragStartX: 0,      // 卷帘
            
            // 倾斜摄影
			gisSetting: {},
            gis3dTilesData: [],
            gisTiltphotoData: [],


            // 图标大小
            big_width: 54,
            big_height: 59,
            little_width: 36,
            little_height: 39,   
        }
    },
    
    mounted (){
        
        XE.ready().then(this.init); 

    },
    
    methods: {
        
        init() {


            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            this.initGisDem();

            MapAddDom_Amap(this.viewer);                            // 高德
            this.dom_tianditu = MapAddDom_Tianditu(this.viewer);    // 天地图



            // 卷帘对比
            // this.dom_tianditu.minificationFilter = Cesium.TextureMinificationFilter.NEAREST;
            // this.dom_tianditu.magnificationFilter = Cesium.TextureMagnificationFilter.NEAREST;

            // this.dom_tianditu.splitDirection = Cesium.ImagerySplitDirection.RIGHT;

            // var slider = document.getElementById("slider");
            // this.viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;


            // document
            //     .getElementById("slider")
            //     .addEventListener("mousedown", this.mouseDown, false);
            // window.addEventListener("mouseup", this.mouseUp, false);

                

            this.initZT();

            // 三维建筑模型
            this.init3DTiles();
            



            var that = this;


            MapFlyToDuration(this.viewer, 1, 119.35072363592676, 26.083644838944874, 3601.6125288002518, 0, -90, 0);
            // MapFlyToDuration(this.viewer, 1, 119.15589345462094, 25.463975441022153, 30739.40514037966, 0, -90, 0);
        

            //实体左键点击事件
            var that = this;
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);

                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {             
                    // that.$parent.selectPtgc(pick.id.id);

                    // 选中放大
                    that.SelectPin(pick.id.typeid, pick.id.id);

                    that.openvrimg(pick.id.typeid, pick.id.id, pick.id.ztindex)

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
            


            var labeltabs = document.querySelectorAll(".nav ul li p")
            for (var i = 0; i<labeltabs.length; i++) {
                labeltabs[i].addEventListener('click', function() {
                    
                    for (var j = 0; j < labeltabs.length; j++) {
                        labeltabs[j].className = '';
                    }
                    this.className = 'currentqwys';

                    var index = this.getAttribute('index');  
                    switch(index) {
                        case "0":
                            that.showPOI(0, true, false);
                            break;
                        default:
                            that.showPOI(parseInt(index), true, true);
                            break;
                    }                        
                })
            }
        },

        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                // MapAddDem(this.viewer, "http://39.107.47.88:8015/fjsmart3d/3dtiles/dem/fujian/quansheng");
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },

        
        // 添加三维建筑模型
        init3DTiles() {

			// Gis配置（15）
			getCode("gis_set", "15").then(res=>{
				this.gisSetting = res.data[0];					


                // 三维模型
                get3dTiles().then(res=>{
                    this.gis3dTilesData = res.data;	

                    for (var i=0; i<this.gis3dTilesData.length; i++) {
                        if (this.gis3dTilesData[i].isshow == 0) {
                            continue;
                        }

                        var url = this.gisSetting.value1 + '3dtiles/' + this.gis3dTilesData[i].name + '/tileset.json';

                        var strarr_material = this.gis3dTilesData[i].arr_material.split(",");
                        var arr_material = strarr_material.map((value)=>{
                            return  parseFloat(value);
                        })


                        var strarr_transform = this.gis3dTilesData[i].arr_transform.split(",");
                        var arr_transform = strarr_transform.map((value)=>{
                            return  parseFloat(value);
                        })

                        MapAdd3DTiles(this.viewer, url, arr_transform, arr_material);
                    }                                    
                })


                // // 倾斜摄影
                // getTiltphoto().then(res=>{
                //     this.gisTiltphotoData = res.data;	

                //     for (var i=0; i<this.gisTiltphotoData.length; i++) {
                //         if (this.gisTiltphotoData[i].isshow == 0) {
                //             continue;
                //         }

                //         var url = this.gisSetting.value1 + 'tiltphoto/' + this.gisTiltphotoData[i].pathkey + '/tileset.json';
                //         var heightoffset = this.gisTiltphotoData[i].heightoffset

                //         console.log("getTiltphoto: " + i + "  " + url  + "  " + heightoffset)
                        
                //         MapAddTiltPhoto(this.viewer, url, heightoffset);                        
                //         // MapAddTiltPhoto(this.viewer, "http://127.0.0.1:8015/tiltphoto/2/tileset.json", -17.0);
                //     }                                    
                // })

			})
        },

        // 卷帘对比
        mouseUp() {
            window.removeEventListener("mousemove", this.sliderMove, true);
        },
        mouseDown(e) {
            var slider = document.getElementById("slider");
            this.dragStartX = e.clientX - slider.offsetLeft;
            window.addEventListener("mousemove", this.sliderMove, true);
        },
        sliderMove(e) {
            var slider = document.getElementById("slider");
            var splitPosition = (e.clientX - this.dragStartX) / slider.parentElement.offsetWidth;
            slider.style.left = 100.0 * splitPosition + "%";
            this.viewer.scene.imagerySplitPosition = splitPosition;
        },


        // 
        initZT() {
            this.ztData = [];
            
            for (var i=0; i<8; i++) {

                this.ztData.push({});
                getZtdt(i+1).then(res=>{

                    if (res.data.length > 0) {
                        var ztid = parseInt(res.data[0].ztid);

                        this.ztData[ztid-1] = res.data;	
                        this.showPOI(ztid, false, false);
                    }
                })

            }


			// 专题地图（13）的多媒体资源设置
			getMedia("13").then(res=>{
				this.mediaSetting = res.data[0];	
				// console.log(this.mediaSetting.prefix);
				
			})
        },

        // 显示兴趣点
        showPOI(ztid, isclear, showname) {
            // console.log("showPOI ztid = " +ztid);

            if (isclear) {
                MapViewerRemoveAll(this.viewer);
            }
            
            this.currentid = 0;
            
            var fromindex = 0;
            var toindex = 7;
            if (ztid > 0) {
                fromindex = ztid-1;
                toindex = ztid-1;
            }

            
            for (var index=fromindex; index<=toindex; index++) {
                for (var i=0; i<this.ztData[index].length; i++) {
                      
                    var name = ""
                    if (showname) {
                        name = this.ztData[index][i].simplename;
                    }

                    this.AddPin(index+1, this.ztData[index][i].id, i, name, this.ztData[index][i].longitude,this.ztData[index][i].latitude);     

                }
            }

        },


        // 添加兴趣点
        AddPin(typeid, id, ztindex, name, lat, lng){
                
            var littleimg = "./images/ztdt/l"+ typeid +".png";
            // console.log(littleimg);

            this.viewer.entities.add({
                id: id,
                name: name,
                typeid: typeid,
                ztindex: ztindex,
                position: Cesium.Cartesian3.fromDegrees(lat, lng),
                label: {
                    text: name,
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    pixelOffset: new Cesium.Cartesian2(0, -this.little_height-20), //偏移量
                },
                billboard: {                    
                    image: littleimg,
                    width: this.little_width,
                    height: this.little_height,
                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
        },

        // 选择兴趣点
        SelectPin(typeid, id) {
            console.log("typeid="+typeid+"; id="+id)
            
            // 当前如果有选中，就还原
            if (this.currentid > 0) {
                var oldentity = this.viewer.entities.getById(this.currentid);
                
                var littleimg = "./images/ztdt/l"+ oldentity.typeid +".png";

                oldentity.billboard.image = littleimg;
                oldentity.billboard.width = this.little_width;
                oldentity.billboard.height = this.little_height;
                
                oldentity.label.scale = 0.6;
                oldentity.label.fillColor = Cesium.Color.WHITE;
                oldentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.little_height-20);
                oldentity.label.showBackground = false;

                this.currentid = 0;
            }

            // 新选中的放大
            var newentity = this.viewer.entities.getById(id);
            
            var bigimg = "./images/ztdt/b"+ newentity.typeid +".png";

            newentity.billboard.image = bigimg;
            newentity.billboard.width = this.big_width;
            newentity.billboard.height = this.big_height;
            
            
            newentity.label.scale = 0.8;
            newentity.label.fillColor = Cesium.Color.BLACK;
            newentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.big_height-20);
            newentity.label.showBackground = true;
            newentity.label.backgroundColor = new Cesium.Color(0.5, 0.6, 1, 0.8),

            this.currentid = id;

        },

        
		openvrimg(typeid, id, ztindex) {

            var obj = this.ztData[typeid-1][ztindex];
            if (obj.vrpano.length == 0) {
                return;
            }

			var vrURL= this.mediaSetting.prefix + obj.ztname + "/" + this.mediaSetting.vrimg + "" + obj.vrpano
			console.log(vrURL)

			this.$parent.openVRImg(this.ptid, vrURL);
		},
    
    }               

}

</script>


<style scoped>

    #slider {
        position: absolute;
        left: 50%;
        top: 0px;
        background-color: #d3d3d3;
        width: 3px;
        height: 100%;
        z-index: 1;
    }

    #slider:hover {
        cursor: ew-resize;
    }


</style>