import {request} from './request';


// 创新分类
export function getCxfl(){
  return request({
    url:'/api/v1/kjcx/getcxfl',
  })
}

// 创新项目
export function getCxxm(cxflid){
  return request({
    url:'/api/v1/kjcx/getcxxm?cxflid='+cxflid,
  })
}
export function getCxxmById(id){
  return request({
    url:'/api/v1/kjcx/getcxxmbyid?id='+id,
  })
}


// 专题地图
export function getZtdt(ztid){
  return request({
    url:'/api/v1/ztdt/getztdt?ztid='+ztid,
  })
}




