<template>
  
	<el-dialog title="" v-model="dialogVisible" top="23vh" width="0%" @close='closeDialog'>
		<div class="dialog">       
            <p class="caption">实景VR</p>
            <p class="close" @click="close"></p>

            <div class="diaimg">
				<iframe style="border:none" width="100%" height="100%" v-bind:src="inframe"></iframe>
            </div>
		</div>
	</el-dialog>

</template>


<script>

export default {

    data() { 
		return { 
			dialogVisible: false,
			inframe: "",
		} 	
	}, 

	computed: {
		player() {
			return this.$refs.videoPlayer.player;
		}
	},


	methods: {

		open(vrURL) {
			this.dialogVisible = true;	
			this.inframe = vrURL;
		},

        close() {
			this.dialogVisible = false;			
        },

		closeDialog(){
			this.dialogVisible = false;		
		},

    }

}
</script>


<style>

.el-dialog{
    position: absolute;
    width: 1px;
    height: 1px;
 
    top: -300px;
    left: 0px; 
}

.dialog {
    position: absolute;
	top: 110px;
	left: 670px;
	width: 2500px;
	height: 900px;
	background: url("~@/assets/images/main/dialogback.png") no-repeat; 
}

.dialog .caption {
    position: absolute;

	top: 20px;
	left: 55px;
	font-size: 16px;
	color: rgb(255, 255, 255, 1);	
}
.dialog .close {
    position: absolute;

	top: 15px;
	right: 15px;
	width: 32px;
	height: 32px;    
	background: url("~@/assets/images/main/dialogclose.png") no-repeat; 
}

.diaimg {
    position: absolute;    
	top: 50px;
	left: 50px;
	width: 2400px;
	height: 803px;
}


</style>